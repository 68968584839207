/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const scrollTo = (id) => () => {
	const el = document.querySelector(id)
	if (el) return el.scrollIntoView({ behavior: "smooth", block: "start" });
	return false
  }
  
export const onRouteUpdate = ({ location: { hash } }) => {
// console.log(location)
if (hash) {
	window.setTimeout(scrollTo(hash), 10)
}
}