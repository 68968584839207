exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-bokademo-js": () => import("./../../../src/pages/bokademo.js" /* webpackChunkName: "component---src-pages-bokademo-js" */),
  "component---src-pages-chef-js": () => import("./../../../src/pages/chef.js" /* webpackChunkName: "component---src-pages-chef-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-engagemang-js": () => import("./../../../src/pages/engagemang.js" /* webpackChunkName: "component---src-pages-engagemang-js" */),
  "component---src-pages-enps-js": () => import("./../../../src/pages/enps.js" /* webpackChunkName: "component---src-pages-enps-js" */),
  "component---src-pages-fragemodell-js": () => import("./../../../src/pages/fragemodell.js" /* webpackChunkName: "component---src-pages-fragemodell-js" */),
  "component---src-pages-funktioner-js": () => import("./../../../src/pages/funktioner.js" /* webpackChunkName: "component---src-pages-funktioner-js" */),
  "component---src-pages-gratis-test-hemarbete-js": () => import("./../../../src/pages/gratis-test-hemarbete.js" /* webpackChunkName: "component---src-pages-gratis-test-hemarbete-js" */),
  "component---src-pages-hr-blogg-js": () => import("./../../../src/pages/hr-blogg.js" /* webpackChunkName: "component---src-pages-hr-blogg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobb-js": () => import("./../../../src/pages/jobb.js" /* webpackChunkName: "component---src-pages-jobb-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-medarbetarengagemang-js": () => import("./../../../src/pages/medarbetarengagemang.js" /* webpackChunkName: "component---src-pages-medarbetarengagemang-js" */),
  "component---src-pages-medarbetarundersokning-js": () => import("./../../../src/pages/medarbetarundersokning.js" /* webpackChunkName: "component---src-pages-medarbetarundersokning-js" */),
  "component---src-pages-mikroundersokningar-js": () => import("./../../../src/pages/mikroundersokningar.js" /* webpackChunkName: "component---src-pages-mikroundersokningar-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-omoss-js": () => import("./../../../src/pages/omoss.js" /* webpackChunkName: "component---src-pages-omoss-js" */),
  "component---src-pages-produkten-js": () => import("./../../../src/pages/produkten.js" /* webpackChunkName: "component---src-pages-produkten-js" */),
  "component---src-pages-prova-js": () => import("./../../../src/pages/prova.js" /* webpackChunkName: "component---src-pages-prova-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

